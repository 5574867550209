import React from "react";
// import im1 from '../../images/service-details/img-3.jpg'
import im2 from "../../images/service-details/img-1.jpg";
// import {Link} from 'react-router-dom'
import "./style.css";

const ServicesSingle = (props) => {
  return (
    <>
      <head>
        <title>Routine Maintenance | Momelizza</title>
        <meta
          name="description"
          content="For your car’s routine service and maintenance, Momelizza provides a comprehensive facility."
        />
      </head>
      <div className="hx-service-dt-area section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-12 col-sm-12 col-12"></div>
            <div className="col-lg-12">
              <div className="hx-service-dt-right">
                <div className="hx-service-dt-img">
                  <img src={im2} alt="" />
                </div>
                <h3>Service Description</h3>
                <p>
                  Certain servicing of your vehicle at regular intervals is
                  extremely important. It ensures that the car is in proper
                  condition, and simultaneously, it helps in identifying any
                  core issues at a very early stage. Momelizza's Routine
                  Maintenance service essentially checks the overall well-being
                  of the vehicle. Under this service regime, we change all the
                  essential oils in various vital parts of the car. We also
                  check if the rotation of the tire is well oriented to achieve
                  the ideal condition or not. Lastly, we check up on the coolant
                  level to ascertain whether it needs topping.
                </p>

                <h3>The Service Procedure</h3>
                <p>
                  Our routine maintenance service starts with thoroughly
                  scrutinizing all essential parts and then replacing the fluids
                  that need changing. This allows us to provide an ideal routine
                  upkeep of the car.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServicesSingle;
