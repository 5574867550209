import React from "react";
import ContactForm from "../CommentForm";
import ct1 from "../../images/contact/img-1.png";
import "./style.css";

const Contactpage = () => {
  return (
    <>
      <head>
        <title>Contact Us | Momelizza</title>
        <meta
          name="description"
          content="For any car-related issues or upgrades, please contact us without any hesitation."
        />
      </head>
      <div className="contact-page-area">
        <div className="hx-contact-area-s2 section-padding">
          <div className="container">
            <div className="row">
              <div className="col-lg-5 col-md-12">
                <div className="hx-contact-img">
                  <img src={ct1} alt="" />
                </div>
              </div>
              <div className="col col-lg-7 col-md-12 col-sm-12 col-12">
                <div className="hx-contact-content">
                  <h2>Get In Touch</h2>
                  <div className="hx-contact-form">
                    <ContactForm />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="hx-contact-grid-area section-padding">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                <div className="hx-contact-gd-wrap">
                  <div className="hx-contact-gd-icon">
                    <i className="fi flaticon-call"></i>
                  </div>
                  <div className="hx-contact-gd-text">
                    <h4>Call Us Now</h4>
                    <span>+85 225217865</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                <div className="hx-contact-gd-wrap">
                  <div className="hx-contact-gd-icon">
                    <i className="fi flaticon-message"></i>
                  </div>
                  <div className="hx-contact-gd-text">
                    <h4>Mail Us Today</h4>
                    <span>info@momelizza.com</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                <div className="hx-contact-gd-wrap">
                  <div className="hx-contact-gd-icon">
                    <i className="fi flaticon-placeholder"></i>
                  </div>
                  <div className="hx-contact-gd-text">
                    <h4>Our Location</h4>
                    <span>Connaught Rd Central, Central, Hong Kong</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col col-xs-12">
                <div className="contact-map">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d2293.0169437230247!2d114.1592925486792!3d22.2817209818396!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjLCsDE2JzU0LjIiTiAxMTTCsDA5JzQyLjIiRQ!5e1!3m2!1sen!2sin!4v1727079934245!5m2!1sen!2sin"
                    title="map"
                    // allowfullscreen
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contactpage;
