import React from "react";
import abimg2 from "../../images/about/about-pic.png";
// import {Link} from 'react-router-dom'
import "./style.css";

const AboutSection = () => {
  // const ClickHandler = () =>{
  //     window.scrollTo(500, 0);
  //  }
  return (
    <section className="about-section">
      <head>
        <title>Our Story | Momelizza</title>
        <meta
          name="description"
          content="We began with the commitment to make our customers satisfied through optimal car repair solutions."
        />
      </head>
      <div className="content-area">
        <div className="left-content">
          <img src={abimg2} alt="" />
        </div>
        <div className="right-content">
          <div className="about-content">
            <div className="section-title">
              <span>About Momelizza</span>
              <h2>Precision, Expertise & Performance</h2>
            </div>
            <div className="details">
              <p>
                At Momelizza, we strictly believe that cars are like our babies.
                Like we properly look after our babies for their optimal
                well-being, our cars should also be maintained by providing the
                required attention and necessary services to it. Headquartered
                in Hong Kong, Momelizza's skilled mechanics are devoted to
                guaranteeing your car remains at its peak performance. Beginning
                with an inspection of your car to resolve any issues, we are
                always ready to help you get an ideal car repair experience.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;
