import React, { Component } from "react";
// import Slider from "react-slick";
import "./style.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import testimonialImg_1 from "../../images/testimonial/2.jpg";

class Testimonial extends Component {
  render() {
    return (
      <>
        <head>
          <title>Our Terms & Conditions | Momelizza</title>
          <meta
            name="description"
            content="Go through this document in detail to be aware of the terms for using our car repair solutions."
          />
        </head>
        <div className="hx-testimonial-area">
          <div className="container">
            <div className="hx-testimonial-active owl-carousel">
              <h2>Terms & Conditions of Momelizza</h2>
              <p>
                Dear visitor, welcome to the Momelizza website. Once you access
                or make use of our website and services, you agree to abide by
                these Terms & Conditions. Take the time to read them because
                they form the basis of the rules and regulations governing the
                use of our website and the services that we provide. Thank you
                for not agreeing with these terms if you choose not to continue
                using our website.
              </p>

              <br />
              <h5>1. Services Offered</h5>
              <p>
                Servicing and car repairs are offered by Momelizza to customers
                in Hong Kong. The company includes routine maintenance and
                complex repairs but is not limited to it; checking, denting,
                painting, and custom repairs are done by a qualified technician
                who pays attention to details.
              </p>

              <br />
              <h5>2. Booking an Appointment</h5>
              <p>
                Our website contact form and phone/emails to info@momelizza.com
                are available for customers to book services with us. If we
                can't make the booking on your preferred date and time, we'll
                get in touch with you through email or phone to confirm the
                booking and provide all the necessary information related to
                your service.
              </p>

              <br />
              <h5>3. Terms Related to Payment </h5>
              <p>
                All cash payments are rendered for services given by Momelizza.
                We do not accept credit cards, bank transfers, or any digital
                form of payment. The payment will be rendered at the time of
                completion of agreed services. However, in case that services
                will not be paid once the work is done, the vehicle may be held
                until full payment is made on the said services.
              </p>

              <br />
              <h5>4. Obligations of a Customer</h5>
              <p>You agree to the following as a customer: </p>
              <div
                style={{
                  marginLeft: "20px",
                  color: "#666",
                  marginBottom: "10px",
                }}
              >
                <li>
                  We are proud to deliver accurate information and current
                  information available at the time of booking services with us.
                </li>
                <li>
                  Ensure the car is in a safe and accessible condition for our
                  technicians to do the necessary work.
                </li>
                <li>
                  Collect your vehicle in due time after your services have been
                  completed and payment has been made.
                </li>
              </div>
              <p>
                We can refuse service to any user if the vehicle is clearly
                dangerous for our staff to deal with; or if inappropriate
                behavior or misuse of our services occurs.
              </p>

              <br />
              <h5>5. Limitations and Warranties Linked with Our Services</h5>
              <p>
                While we aim at giving quality repair and maintenance services,
                we must accept the fact that certain problems with your vehicle
                are out of our realm. If it happens that the repair cannot be
                done, we'd inform you immediately and discuss other options.
              </p>
              <p>
                Momelizza makes no warranties, implied or express, about parts
                or services except as offered by the manufacturer of the parts
                used when in repair. Our company will do what is within its
                means to ensure that all repairs are made with good workmanship
                but is not liable for problems arising after the repair from
                conditions that existed before the repair, nor from defects
                unrelated to the repair.
              </p>

              <br />
              <h5>6. Cancellations and Rescheduling</h5>
              <p>
                If you want to cancel or reschedule your appointment, please let
                us know at least 24 hours ahead of the scheduled appointment
                time. A timely notice not provided may delay your rebooking
                opportunity and perhaps the time slot you want will be lost. We
                are human, and so are mistakes. If something comes up to keep
                you from coming at the scheduled time we made for your
                appointment, we'll work in our schedule to make it right for
                you.
              </p>

              <br />
              <h5>7. Liability</h5>
              <p>
                Momelizza shall not be responsible for any item left in the
                vehicle by you during the service. We would request our
                customers to take out all the valuables from the vehicle before
                leaving the same with Momelizza. We handle your vehicle with
                extreme attention but Momelizza shall not be responsible for any
                consequential or collateral damages or loss suffered due to
                accessing this website or using the services here available on
                this website.
              </p>

              <br />
              <h5>8. Terms & Conditions Alterations</h5>
              <p>
                Momelizza shall have the right to change these Terms &
                Conditions at any time without notice. Changes will be posted on
                this page and are your responsibility to periodically check the
                terms. You shall continue to use the website and our services
                upon any changes.
              </p>

              <br />
              <h5>9. Contact Us</h5>
              <p>
                For any concern or confusion connected with our Terms &
                Conditions or any other aspect related to our services, please
                e-mail us at info@momelizza.com.
              </p>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Testimonial;
