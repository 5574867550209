import React, { Component } from "react";
import { Link } from "react-router-dom";
import heroImage from "../../images/slider/hero-image-1.png";
import "./style.css";

class SimpleSlider extends Component {
  render() {
    return (
      <section className="hero hero-static-image">
        <div className="container">
          <div className="row">
            <div className="row col-lg-6 col-md-7 slide-caption">
              <div className="slide-title">
                <h1>A Trusted Auto Repair Company in the HK!</h1>
              </div>
              <div className="slide-subtitle">
                <p>
                  Seeking an ideal car repair service in HK? Whether you are
                  bothered by the dent in your brand-new car or the
                  fast-draining battery of your EV, Momelizza is here to deliver
                  lasting solutions to all your car issues.
                </p>
              </div>
              <div className="btns">
                <Link to="/contact" className="theme-btn-s2">
                  Contact Us
                </Link>
              </div>
            </div>
            <div className="row col-lg-6 col-md-5 slide-caption">
              <img src={heroImage} alt="hero_image" />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default SimpleSlider;
