import React from "react";

// components
import Navbar from "../../components/Navbar";
import PageTitle from "../../components/breadcumb";
import ServicesSingle5 from "../../components/servicesSingle5";
import FooterSection from "../../components/Footer";

const ServiceDetails = () => {
  return (
    <div className="page-wrapper inner-page home-s-3">
      <Navbar />
      <PageTitle
        bdtitle={"Routine Maintenance"}
        bdsub={"Routine Maintenance"}
      />
      <ServicesSingle5 />
      <FooterSection />
    </div>
  );
};

export default ServiceDetails;
