import React from "react";
// import im1 from '../../images/service-details/img-3.jpg'
import im2 from "../../images/service-details/img-1-2.jpg";
// import {Link} from 'react-router-dom'
import "./style.css";

const ServicesSingle = (props) => {
  return (
    <>
      <head>
        <title>Complex Repairs | Momelizza</title>
        <meta
          name="description"
          content="Struggling with a major issue in your car? Avail our complex repair service to get it fixed now."
        />
      </head>
      <div className="hx-service-dt-area section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-12 col-sm-12 col-12"></div>
            <div className="col-lg-12">
              <div className="hx-service-dt-right">
                <div className="hx-service-dt-img">
                  <img src={im2} alt="" />
                </div>
                <h3>Service Description</h3>
                <p>
                  If a car has not been serviced for a long time, it would
                  likely have been developing some underlying issues that are
                  unnoticed until the car owner encounters a major breakdown.
                  For any such vehicle-related problems, Momelizza offers
                  holistic repair facilities to eradicate any complex issue.
                  This could include engine issues or major battery issues, to
                  some weird sound in the suspension. Upon a detailed checking
                  process is performed, we begin our work to repair the car part
                  to give it a fresh condition.
                </p>

                <h3>The Service Procedure</h3>
                <p>
                  If the car cannot be moved upon breakdown, we send a tow truck
                  to get it to our workshop and do a detailed inspection.
                  Following the identification of the issue, we discuss it with
                  the clients and start our complex repair work.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServicesSingle;
