import React from "react";

// components
import Navbar from "../../components/Navbar";
import PageTitle from "../../components/breadcumb";
import ServicesSingle4 from "../../components/servicesSingle4";
import FooterSection from "../../components/Footer";

const ServiceDetails = () => {
  return (
    <div className="page-wrapper inner-page home-s-3">
      <Navbar />
      <PageTitle
        bdtitle={"Car Denting and Painting"}
        bdsub={"Car Denting and Painting"}
      />
      <ServicesSingle4 />
      <FooterSection />
    </div>
  );
};

export default ServiceDetails;
