import React from "react";

// components
import Navbar from "../../components/Navbar";
import PageTitle from "../../components/breadcumb";
import ServicesSingle2 from "../../components/servicesSingle2";
import FooterSection from "../../components/Footer";

const ServiceDetails = () => {
  return (
    <div className="page-wrapper inner-page home-s-3">
      <Navbar />
      <PageTitle
        bdtitle={"Complex Repairs"}
        bdsub={"Complex Repairs"}
      />
      <ServicesSingle2 />
      <FooterSection />
    </div>
  );
};

export default ServiceDetails;
