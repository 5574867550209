import React from "react";
// import im1 from '../../images/service-details/img-3.jpg'
import im2 from "../../images/service-details/img-1-5.jpg";
// import {Link} from 'react-router-dom'
import "./style.css";

const ServicesSingle = (props) => {
  return (
    <>
      <head>
        <title>Car Custom Repairs | Momelizza</title>
        <meta
          name="description"
          content="Want to give your car a facelift? We have some amazing custom upgrade solutions for your car. "
        />
      </head>
      <div className="hx-service-dt-area section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-12 col-sm-12 col-12"></div>
            <div className="col-lg-12">
              <div className="hx-service-dt-right">
                <div className="hx-service-dt-img">
                  <img src={im2} alt="" />
                </div>
                <h3>Service Description</h3>
                <p>
                  Custom services offered at our workshop are something you
                  should opt for if you want to upgrade your car to a next
                  level. We offer detailed consultation, and based on that,
                  efficient custom upgradation as per the choice of the car
                  owners. If you want to upgrade your hatchback to a compact
                  SUV, we will acquire the required parts and amenities from
                  outside the country to facilitate the desired upgrade. We also
                  offer services to change your car's original paint. After the
                  upgradation is done we review the outcome with the client to
                  see if something is missing or not so that we can work on it.
                </p>

                <h3>The Service Procedure</h3>
                <p>
                  The custom repair process starts with a detailed conversation
                  with a client, and upon the discussion, we provide an estimate
                  to the client. If we get a green signal from the customer, we
                  begin our work.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServicesSingle;
