import React from "react";

// components
import Navbar from "../../components/Navbar";
import PageTitle from "../../components/breadcumb";
import FooterSection from "../../components/Footer";
import Testimonial1 from "../../components/testimonial1";

const TestimonialPage = () => {
  return (
    <div className="page-wrapper inner-page home-s-3">
      <Navbar />
      <PageTitle
        bdtitle={"Privacy Policy of Momelizza"}
        bdsub={"Privacy Policy"}
      />
      <Testimonial1 />
      <FooterSection />
    </div>
  );
};

export default TestimonialPage;
