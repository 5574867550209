import React from "react";
// import im1 from '../../images/service-details/img-3.jpg'
import im2 from "../../images/service-details/img-1-6.jpg";
// import {Link} from 'react-router-dom'
import "./style.css";

const ServicesSingle = (props) => {
  return (
    <>
      <head>
        <title>Car AC Services | Momelizza</title>
        <meta
          name="description"
          content="Avail our proficient AC repair service to get your vehicle's air conditioner fixed. "
        />
      </head>
      <div className="hx-service-dt-area section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-12 col-sm-12 col-12"></div>
            <div className="col-lg-12">
              <div className="hx-service-dt-right">
                <div className="hx-service-dt-img">
                  <img src={im2} alt="" />
                </div>
                <h3>Service Description</h3>
                <p>
                  As Hong Kong is in the sub-tropical region, it gets quite hot
                  often. Thus, almost all cars need an active and efficient AC.
                  However, if the AC mechanism is not looked after cautiously,
                  it can turn out to be inefficient. So, Momelizza has come up
                  with a comprehensive AC service solution for cars. We check to
                  see if your vehicle needs AC gas refill or not. If any
                  internal system is not functioning properly, our proficient
                  mechanics indulge in offering a thorough repair of the AC,
                  including cleaning the AC vents inside the car.
                </p>

                <h3>The Service Procedure</h3>
                <p>
                  Our approach involves inspecting the entire AC mechanism at
                  first, and then after identifying any underlying issue, we
                  start our repair work. The aim is to hand you over a car with
                  effective AC system.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServicesSingle;
