import React, { Component } from "react";
// import Slider from "react-slick";
import "./style.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import testimonialImg_1 from "../../images/testimonial/2.jpg";

class Testimonial extends Component {
  render() {
    return (
      <>
        <head>
          <title>Our Privacy Policy | Momelizza</title>
          <meta
            name="description"
            content="With this document, we've tried to notify you about our procedure to manage the personal information of our website users that we receive."
          />
        </head>
        <div className="hx-testimonial-area">
          <div className="container">
            <div className="hx-testimonial-active owl-carousel">
              <h2>Privacy Policy of Momelizza</h2>
              <p>
                At Momelizza, we respect your privacy. This Privacy Policy will
                define how we collect, use, and protect your personal
                information when you visit our website or engage with our
                services. We are committed to handling your data responsibly in
                accordance with the laws and regulations of Hong Kong while
                making your experience with us seamless and transparent.
              </p>

              <br />
              <h5>Information We Accumulate </h5>
              <p>
                We might collect the personal details that you provide to us
                through various means, such as when you access our website or
                contact us for our service name, email address, phone number,
                and any other detail we might find relevant to providing you
                with the services you request. This may also include technical
                details like your IP address, browser type, and operating
                system, which we automatically collect to help us maintain the
                functionality and security of our site.
              </p>

              <br />
              <h5>This information is gathered from you when</h5>
              <div style={{ marginLeft: "20px", color: "#666" }}>
                <li>You volunteer information in forms on our website</li>
                <li>You are reaching out to us by email or phones</li>
                <li>You visit and engage with functionality on our site</li>
              </div>

              <br />
              <h5>The Way We Utilize Your Data </h5>
              <p>We use the gathered information to: </p>
              <div
                style={{
                  marginLeft: "20px",
                  color: "#666",
                  marginBottom: "10px",
                }}
              >
                <li>
                  Run services for which you requested, whether fix bookings or
                  follow up on the inquiry
                </li>
                <li>
                  Enhance functionality of our website toward smooth running
                </li>
                <li>
                  Communicate with you, including to send you information
                  related to your requested services or promotions (only if you
                  opted in)
                </li>
                <li>
                  Track the use of our website to assist us in improving your
                  user experience
                </li>
              </div>
              <p>
                We will never share, sell, or rent your information to third
                parties for direct marketing purposes. Your data are used solely
                within our company to ensure that we deliver the services you
                expect from us.
              </p>

              <br />
              <h5>The Way We Store and Safeguard Your Data </h5>
              <p>
                We are concerned about your personal information; therefore, the
                data we collect are stored safely, and proper measures are taken
                to safeguard these from unauthorized access, alteration, or
                disclosure. The physical and digital security practices have
                been implemented for the safe securing of your information.
              </p>
              <p>
                Note, however, that no method of transmitting over the internet
                or the storage of data is 100% secure. As such we cannot
                guarantee the security of our database; we do take all
                reasonable measures to do so. If a potential breach of security
                occurs, we will endeavor to notify you and take reasonable
                measures to mitigate any damage that may be caused.
              </p>

              <br />
              <h5>Your Rights </h5>
              <p>You have the following rights: </p>
              <div
                style={{
                  marginLeft: "20px",
                  color: "#666",
                  marginBottom: "10px",
                }}
              >
                <li>To access the personal data, we hold about you</li>
                <li>
                  Enhance functionality of our website toward smooth running
                </li>
                <li>Request correction of any inaccuracies in your data</li>
                <li>Ask that we delete your personal information</li>
                <li>Withdraw consent to any marketing communications </li>
              </div>
              <p>
                You can exercise these rights, or if you have questions or
                concerns about your personal data, please write to us at
                info@momelizza.com. We're here to answer any questions or
                requests you might have related to your privacy.
              </p>

              <br />
              <h5>Cookies and Tracking </h5>
              <p>
                Our site uses cookies and other information-gathering
                technologies to help tailor your experience on our site. These
                tools enable us to remember your preferences and analyze the use
                of our website. You can set your browser to notify you when you
                receive a cookie, giving you an opportunity to decide whether to
                accept it. You can also turn off cookies via your browser;
                however, please note that some of the functionalities on our
                site may not work if you turn off cookies.
              </p>

              <br />
              <h5>Alterations to This Privacy Policy </h5>
              <p>
                We may revise this Privacy Policy at any time by posting a new
                version on this website. All changes will apply only to
                activities on or after the effective date of the revised Privacy
                Policy. This list will be disclosed each time we post major
                revisions. We encourage you to check back regularly to stay
                informed about our practices and the way your information will
                be used.
              </p>

              <br />
              <h5>Contact Us </h5>
              <p>
                For all further confusion or concerns associated with our
                Privacy Policy, or how we handle personal information, please do
                contact us at info@momelizza.com. We highly value your faith and
                devote to respecting your privacy at all times.
              </p>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Testimonial;
