import React, { Component } from "react";

let errorTag = {
  color: "red",
};

class ContactForm extends Component {
  state = {
    name: "",
    email: "",
    number: "",
    notes: "",
    error: {},
    message: "",
    isSending: false, // State to track the sending status
  };

  changeHandler = (e) => {
    const error = this.state.error;
    error[e.target.name] = "";

    this.setState({
      [e.target.name]: e.target.value,
      error,
    });
  };

  submitHandler = (e) => {
    e.preventDefault();

    const { name, email, number, notes, error } = this.state;

    // Form validation
    if (name === "") {
      error.name = "Please enter your name.";
    }
    if (email === "") {
      error.email = "Please enter your email.";
    }
    if (number === "") {
      error.number = "Please enter your phone number.";
    }
    if (notes === "") {
      error.notes = "Please enter your message.";
    }

    this.setState({ error });

    // Proceed only if there are no errors
    if (!error.name && !error.email && !error.number && !error.notes) {
      this.setState({ isSending: true }); // Disable button and inputs
      let formData = {
        name: this.state.name,
        email: this.state.email,
        phone: this.state.number,
        message: this.state.notes,
      };

      // Make the POST request to the server
      fetch("https://acomal.top/send-mail/6/", {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify(formData),
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.msg === "success") {
            this.setState({
              message: "Message Sent Successfully!",
              name: "",
              email: "",
              number: "",
              notes: "",
              error: {},
              isSending: false, // Enable button and inputs after sending
            });
          } else {
            this.setState({
              message: "Failed to send the message!",
              isSending: false, // Enable button and inputs after failure
            });
          }
        })
        .catch(() => {
          this.setState({
            message: "An error occurred while sending the message",
            isSending: false, // Enable button and inputs after error
          });
        });
    }
  };

  render() {
    const { name, email, number, notes, error, message, isSending } =
      this.state;

    return (
      <form onSubmit={this.submitHandler}>
        <div className="contact-form form-style row">
          <div className="col-12 col-lg-6">
            <input
              style={{ width: "100%" }}
              type="text"
              value={name}
              onChange={this.changeHandler}
              placeholder="Your Name*"
              id="fname"
              name="name"
              disabled={isSending}
            />
            <p style={errorTag}>{error.name ? error.name : ""}</p>
          </div>
          <div className="col col-lg-6">
            <input
              style={{ width: "100%" }}
              type="text"
              placeholder="Phone"
              onChange={this.changeHandler}
              value={number}
              id="number"
              name="number"
              disabled={isSending}
            />
            <p style={errorTag}>{error.number ? error.number : ""}</p>
          </div>
          <div className="col-12 col-lg-12">
            <input
              style={{ width: "100%" }}
              type="email"
              placeholder="Your Email"
              onChange={this.changeHandler}
              value={email}
              id="email"
              name="email"
              disabled={isSending}
            />
            <p style={errorTag}>{error.email ? error.email : ""}</p>
          </div>
          <div className="col-12 col-sm-12">
            <textarea
              style={{ width: "100%" }}
              className="contact-textarea"
              value={notes}
              onChange={this.changeHandler}
              placeholder="Message"
              name="notes"
              disabled={isSending}
            ></textarea>
            <p style={errorTag}>{error.notes ? error.notes : ""}</p>
          </div>
          <div
            className="col-12"
            style={{ display: "flex", justifyContent: "end" }}
          >
            <button type="submit" className="theme-btn-s4" disabled={isSending}>
              {isSending ? "Sending..." : "Appointment"}
            </button>
          </div>
        </div>
        {message && (
          <p
            id="message"
            style={{ color: "green", display: "flex", justifyContent: "end" }}
          >
            {message}
          </p>
        )}
      </form>
    );
  }
}

export default ContactForm;
