import React from  'react';

// components
import Navbar from '../../components/Navbar'
import PageTitle from '../../components/breadcumb'
import ServicesSingle1 from '../../components/servicesSingle1'
import FooterSection from '../../components/Footer'


const ServiceDetails = () => {
    return (
      <div className="page-wrapper inner-page home-s-3">
        <Navbar />
        <PageTitle
          bdtitle={"Car Custom Repairs"}
          bdsub={"Car Custom Repairs"}
        />
        <ServicesSingle1 />
        <FooterSection />
      </div>
    );
}

export default ServiceDetails;