import React from "react";
import { Link } from "react-router-dom";
import blog1 from "../../images/blog/img-1.jpg";
import blog2 from "../../images/blog/img-2.jpg";
// import blog3 from "../../images/blog/img-3.jpg";
// import blog4 from "../../images/blog/author.jpg";
import "./style.css";

const BlogSection = () => {
  const ClickHandler = () => {
    window.scrollTo(500, 0);
  };
  return (
    <section className="blog-section section-padding">
      <div className="container">
        <div className="row">
          <div className="col col-xs-12">
            <div className="section-title-s2 text-center">
              <span>Our blog</span>
              <h2>Latest Blogs</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col col-xs-12">
            <div className="blog-grids clearfix">
              <div className="grid">
                <div className="entry-media">
                  <img src={blog1} alt="" />
                </div>
                <div className="details">
                  <h3>
                    <Link
                      onClick={ClickHandler}
                      to="/Signs-Your-Car-Needs-Routine-Maintenance"
                    >
                      Signs Your Car Needs Routine Maintenance
                    </Link>
                  </h3>
                </div>
              </div>
              <div className="grid">
                <div className="entry-media">
                  <img src={blog2} alt="" />
                </div>
                <div className="details">
                  <h3>
                    <Link
                      onClick={ClickHandler}
                      to="/When-Should-You-Consider-Getting-a-Car-Inspection"
                    >
                      When Should You Consider Getting a Car Inspection?
                    </Link>
                  </h3>
                </div>
              </div>
              <div
                className="btns"
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  justifyContent: "center",
                  //   border: "1px solid red",
                }}
              >
                <Link to="/blog" className="theme-btn-s2">
                  See More Blogs
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BlogSection;
