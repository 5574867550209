import React from "react";
import { Link } from "react-router-dom";
import logo from "../../images/logo.png";
// import post1 from '../../images/footer/img-1.jpg'
// import post2 from '../../images/footer/img-2.jpg'
import "./style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDiscord } from "@fortawesome/free-brands-svg-icons";

const FooterSection = () => {
  const ClickHandler = () => {
    window.scrollTo(500, 0);
  };

  return (
    <div className="hx-site-footer-area">
      <div className="hx-site-footer-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-12 footer-t">
              <div className="hx-site-footer-text">
                <div className="hx-site-logo">
                  <Link to="/">
                    <img src={logo} alt="nologo" />
                  </Link>
                </div>
                <p>
                  We’re your one-stop solution for all car-related problems. A
                  dependable partner to resolve all your car worries.
                </p>
                <div className="social">
                  <ul className="d-flex">
                    <li>
                      <Link
                        onClick={ClickHandler}
                        to="https://discord.gg/gwNza9vk"
                      >
                        <FontAwesomeIcon icon={faDiscord} />
                      </Link>
                    </li>
                    <li>
                      <Link onClick={ClickHandler} to="https://x.com/momelizza">
                        <i className="fa fa-twitter" aria-hidden="true"></i>
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={ClickHandler}
                        to="https://www.pinterest.com/momelizza/"
                      >
                        <i className="fa fa-pinterest" aria-hidden="true"></i>
                      </Link>
                    </li>
                    {/* <li>
                      <Link onClick={ClickHandler} to="/">
                        <i className="fa fa-google-plus" aria-hidden="true"></i>
                      </Link>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-6 col-sm-12 footer-t">
              <div className="hx-site-footer-service">
                <h3>Quick Links</h3>
                <div className="service-section">
                  <ul>
                    <li>
                      <Link onClick={ClickHandler} to="/">
                        Home
                      </Link>
                    </li>
                    <li>
                      <Link onClick={ClickHandler} to="/about">
                        About
                      </Link>
                    </li>
                    <li>
                      <Link onClick={ClickHandler} to="/services">
                        Service
                      </Link>
                    </li>
                    {/* <li><Link onClick={ClickHandler} to="/service-details">Service single</Link></li> */}
                    {/* <li><Link onClick={ClickHandler} to="/Pricing">Pricing</Link></li> */}
                    <li>
                      <Link onClick={ClickHandler} to="/blog">
                        Blogs
                      </Link>
                    </li>
                    <li>
                      <Link onClick={ClickHandler} to="/contact">
                        Contact
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 footer-t">
              <h3>Helps</h3>
              <div className="service-section">
                <ul>
                  <li>
                    <Link onClick={ClickHandler} to="/Cookies-Policy">
                      Cookies Policy
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="/Privacy-Policy">
                      Privacy Policy
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="/Terms-&-Conditions">
                      Terms & Conditions
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 footer-t">
              <div className="hx-site-footer-adress">
                <h3>Address</h3>
                <div className="adress-section">
                  <ul>
                    {/* <li>Head Office Address</li> */}
                    <li>Connaught Rd Central, Central, </li>
                    <li>Hong Kong</li>
                  </ul>
                  <ul className="ad">
                    <li>
                      <span>Phone: </span>+85 225217865
                    </li>
                    <li>
                      <span>Email: </span>info@momelizza.com
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="hx-site-footer-bottom">
        <div className="container">
          <div className="hx-site-footer-bottom-content">
            <div className="row">
              <div className="col-12">
                <span>
                  Copyright © 2024 <Link to="/">Momelizza</Link> All rights
                  reserved
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterSection;
