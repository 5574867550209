import React from "react";
// import im1 from '../../images/service-details/img-3.jpg'
import im2 from "../../images/service-details/img-1-3.jpg";
// import {Link} from 'react-router-dom'
import "./style.css";

const ServicesSingle = (props) => {
  return (
    <>
      <head>
        <title>Car Inspection | Momelizza</title>
        <meta
          name="description"
          content="Get every part and corner of your vehicle inspected with our specialized car inspection service. "
        />
      </head>
      <div className="hx-service-dt-area section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-12 col-sm-12 col-12"></div>
            <div className="col-lg-12">
              <div className="hx-service-dt-right">
                <div className="hx-service-dt-img">
                  <img src={im2} alt="" />
                </div>
                <h3>Service Description</h3>
                <p>
                  Momelizza is devoted to facilitating ideal car inspection
                  solutions. Often, car owners find themselves in situations
                  where some immoral mechanics do not check a car's condition
                  thoroughly, which contributes to severe issues for the car in
                  the future. To guarantee our customers the optimal inspection,
                  we use contemporary tools and car-diagnosis instruments to
                  assess every part. Since the majority of modern cars are
                  controlled through electronic systems, using modern tools
                  enables us to get a detailed report on our computers. We also
                  review your car's emissions to double-check the condition of
                  the engine.
                </p>

                <h3>The Service Procedure</h3>
                <p>
                  Our car inspection facility initiatives by first getting the
                  car connected to our computer system, and it checks each
                  separate part one at a time. Following the computerized
                  inspection, we generate a report and submit it to the
                  customer.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServicesSingle;
