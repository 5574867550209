import React, { Component } from "react";
// import Slider from "react-slick";
import "./style.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import testimonialImg_1 from "../../images/testimonial/2.jpg";

class Testimonial extends Component {
  render() {
    //   dots: true,
    //   infinite: true,
    //   speed: 1200,
    //   slidesToShow: 1,
    //   arrows: false,
    //   margin: 50,
    //   autoplay: true,
    //   slidesToScroll: 1,
    //   centerPadding: 30,
    //   focusOnSelect: false,
    //   responsive: [
    //     {
    //       breakpoint: 1024,
    //       settings: {
    //         slidesToShow: 1,
    //         slidesToScroll: 1,
    //         infinite: true,
    //         dots: true,
    //       },
    //     },
    //     {
    //       breakpoint: 600,
    //       settings: {
    //         slidesToShow: 1,
    //         slidesToScroll: 2,
    //         initialSlide: 2,
    //       },
    //     },
    //     {
    //       breakpoint: 480,
    //       settings: {
    //         slidesToShow: 1,
    //         slidesToScroll: 1,
    //       },
    //     },
    //   ],
    // };
    return (
      <>
        <head>
          <title>Our Cookies Policy | Momelizza</title>
          <meta
            name="description"
            content="Get to know about our cookies policy to be mindful of our approach to constantly improve the user experience of our website visitors."
          />
        </head>
        <div className="hx-testimonial-area">
          <div className="container">
            <div className="hx-testimonial-active owl-carousel">
              <h2>Cookies Policy of Momelizza</h2>
              <p>
                At Momelizza, we believe in being honest with its users about
                how we use cookies on our website. Cookies are small files of
                data that are placed on your device when you visit our website.
                These help to enhance your experience in surfing our site by
                remembering your preferences and helping us understand how our
                site is used. We therefore want you to feel comfortable surfing
                our site, knowing your privacy is respected.
              </p>

              <br />
              <h5>What We Use Cookies For </h5>
              <p>
                We use cookies to make your experience better and ensure the
                website works as it should. These little files enable us to
                remember things like your chosen language, your region, and more
                settings, which in turn makes your visits to our site run
                smoother. Cookies also help us monitor what sections of our
                website you find interesting and continue improving on serving
                what you need.
              </p>

              <br />
              <h5>Types of Cookies We Use </h5>
              <div
                style={{
                  marginLeft: "20px",
                  color: "#666",
                  marginBottom: "20px",
                }}
              >
                <li>
                  <b>Strictly Necessary Cookies: </b>
                  These are essential for using the website as expected and
                  allow certain functions on the website to not work.
                </li>
                <li>
                  <b>Performance Cookies: </b>
                  These cookies help us to track how our visitors use the site,
                  giving us the opportunity to improve it, layout and
                  design-wise.
                </li>
                <li>
                  <b>Functionality Cookies:</b>
                  These enable the website to remember the different choices you
                  have chosen to make the experience here more personalized.
                </li>
              </div>

              <br />
              <h5>Control Over Cookies </h5>
              <p>
                You may also disable cookies should you wish to – almost all
                browsers allow it, and if you want to decline cookies, you can
                change your settings so that your browser declines cookies. It
                does have the effect of limiting what you can do on some of our
                site's functionality - so be aware.
              </p>

              <br />
              <h5>Contact Us </h5>
              <p>
                Any queries concerning our Cookies Policy should be directed to
                info@momelizza.com. We will be happy to answer any questions or
                solve a problem you may raise.
              </p>
              <p>
                Continued use of our website implies you accept and agree with
                the cookies usage as set forth in this policy.
              </p>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Testimonial;
