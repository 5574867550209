import React from "react";
import { Link } from "react-router-dom";

import "./style.css";

const Services = (props) => {
  return (
    <>
      <head>
        <title>Our Car Repair Services | Momelizza</title>
        <meta
          name="description"
          content="Find our holistic car repair services, which can be tailored as per our client’s needs."
        />
      </head>
      <div className="service-style-1 section-padding">
        <div className="container">
          <div className="col-12">
            <div className="section-title-s2 text-center">
              <span>What We Offer</span>
              <h2>Our Assured Services</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="service-wrap">
                <div className="service-icon">
                  <i className="fi flaticon-turbo"></i>
                </div>
                <div className="service-text">
                  <Link to="/Routine-Maintenance">
                    <h2>Routine Maintenance</h2>
                    <p>
                      We help you protect the longevity of your vehicle by
                      delivering 360-degree car maintenance services.
                    </p>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4  col-md-6">
              <div className="service-wrap">
                <div className="service-icon-2">
                  <i className="fi flaticon-tyre"></i>
                </div>
                <div className="service-text">
                  <Link to="/Complex-Repairs">
                    <h2>Complex Repairs</h2>
                    <p>
                      With a team of trained professionals, we offer tested and
                      proven repair services for all vehicles.
                    </p>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4  col-md-6">
              <div className="service-wrap">
                <div className="service-icon-3">
                  <i className="fi flaticon-car-1"></i>
                </div>
                <div className="service-text">
                  <Link to="/Car-Inspection">
                    <h2>Car Inspection</h2>
                    <p>
                      With our car inspection solutions, you can get a proper
                      diagnosis of your car.
                    </p>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4  col-md-6">
              <div className="service-wrap">
                <div className="service-icon-4">
                  <i className="fi flaticon-car-repair"></i>
                </div>
                <div className="service-text">
                  <Link to="/Car-Denting-and-Painting">
                    <h2>Car Denting and Painting</h2>
                    <p>
                      High-quality paint protection films help to preserve your
                      vehicle’s shiny look, eliminating dents & scratches.
                    </p>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4  col-md-6">
              <div className="service-wrap">
                <div className="service-icon-5">
                  <i className="fi flaticon-battery"></i>
                </div>
                <div className="service-text">
                  <Link to="/Car-Custom-Repairs">
                    <h2>Car Custom Repairs</h2>
                    <p>
                      Thinking of changing your car’s appearance that relate to
                      your personality? We help with that as well!
                    </p>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4  col-md-6">
              <div className="service-wrap">
                <div className="service-icon-6">
                  <i className="fi flaticon-electricity"></i>
                </div>
                <div className="service-text">
                  <Link to="/Car-AC-Services">
                    <h2>Car AC Services</h2>
                    <p>
                      If your vehicle’s AC has started to smell strange or make
                      noises, connect with Momelizza today.
                    </p>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Services;
