import React from "react";

// components
import Navbar from "../../components/Navbar";
import PageTitle from "../../components/breadcumb";

import BlogDetailsSection1 from "../../components/BlogDetailsSection1";
import FooterSection from "../../components/Footer";

const BlogSingleSidebar = () => {
  return (
    <div className="page-wrapper inner-page home-s-3">
      <Navbar />
      <PageTitle
        bdtitle={"Signs Your Car Needs Routine Maintenance"}
        bdsub={"Blog"}
      />
      <BlogDetailsSection1 />
      <FooterSection />
    </div>
  );
};

export default BlogSingleSidebar;
