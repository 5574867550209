import React from "react";
// import im1 from '../../images/service-details/img-3.jpg'
import im2 from "../../images/service-details/img-1-4.jpg";
// import {Link} from 'react-router-dom'
import "./style.css";

const ServicesSingle = (props) => {
  return (
    <>
      <head>
        <title>Car Denting and Painting | Momelizza</title>
        <meta
          name="description"
          content="For effective dent repair and car painting services, come to our workshop. "
        />
      </head>
      <div className="hx-service-dt-area section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-12 col-sm-12 col-12"></div>
            <div className="col-lg-12">
              <div className="hx-service-dt-right">
                <div className="hx-service-dt-img">
                  <img src={im2} alt="" />
                </div>
                <h3>Service Description</h3>
                <p>
                  Dents and scratches are common these days. With different
                  types of drivers on the road, we never know when we are about
                  to get a scratch or a dent on the body of our cars. Such
                  incidents make your car's appearance go downwards. But,
                  Momelizza offers a holistic dent-repair and painting service
                  that not only ensures a lasting paint but also easily removes
                  any dents. We also utilize high-standard and
                  international-level PPFs and ceramic coating to better
                  safeguard your car's paint. We also offer three years of
                  warranty for PPF and ceramic coating facilities.
                </p>

                <h3>The Service Procedure</h3>
                <p>
                  Against any dents and scratches on your car, we first soften
                  the surface with tools and then apply car primer, followed by
                  the main painting process. After that, we will apply the PPF
                  or ceramic coating, depending on the client's choice.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServicesSingle;
