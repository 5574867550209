import React from "react";
// import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
// import classnames from "classnames";
// import pr1 from "../../images/pricing/img-1.png";
// import { Link } from "react-router-dom";
import "./style.css";

const Pricing = (props) => {
  // const [activeTab, setActiveTab] = useState("1");

  // const toggle = (tab) => {
  //   if (activeTab !== tab) setActiveTab(tab);
  // };

  return (
    <div className="pricing-section">
      {/* <div style={{ marginBottom: "50px" }}>
        <div className="col-12">
          <div className="section-title-s2 text-center">
            <span>How We Work </span>
            <h2>Our Transparent Repair Process</h2>
          </div>
        </div>
        <div
          className="row"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div className="col-12">
          <p>
            At Momelizza, we first fix an appointment, then diagnose & plan,
            followed by repairing, testing, and final touch-ups.
          </p>
          </div>
        </div>
      </div> */}
      <div className="container">
        <div className="col-12">
          <div className="section-title-s2 text-center">
            <span>What Makes Us </span>
            <h2>A Reliable Car Repair Partner</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <p>
              With the goal of preventing unnecessary vehicle breakdowns, we
              deliver each of our client the appropriate auto repair service in
              the HK. We utilize the latest equipment, and proven techniques to
              address each malfunction from the root, providing you with
              exceptional ROI. In addition to that, we maintain complete
              transparency across our services, ensuring you never have to face
              unexpected surprises on the way.
            </p>
            <div
              style={{
                marginLeft: "20px",
                display: "flex",
                gap: "10px",
                flexDirection: "column",
                color: "#666",
              }}
            >
              <li>
                <b>Affordable Services: </b>
                No Hidden Charges
              </li>
              <li>
                <b>Contemporary Facility: </b>Armed with Newest Instruments
              </li>
              <li>
                <b>Tailored Solutions: </b>Complete Transparency
              </li>
              <li>
                <b>Quality-guaranteed: </b> Result-driven services
              </li>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
