import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Homepage from "../HomePage";
import Aboutpage from "../AboutPage";
import ServicePage from "../ServicePage";
import ServiceDetails from "../ServiceSingle";
import ServiceDetails1 from "../ServiceSingle1";
import ServiceDetails2 from "../ServiceSingle2";
import ServiceDetails3 from "../ServiceSingle3";
import ServiceDetails4 from "../ServiceSingle4";
import ServiceDetails5 from "../ServiceSingle5";
import TestimonialPage from "../TestimonialPage";
import TestimonialPage1 from "../TestimonialPage1";
import TestimonialPage2 from "../TestimonialPage2";
import ErrorPage from "../ErrorPage";
import ContactPages from "../ContactPage";
import BlogPage from "../BlogPage";
import BlogSingleSidebar from "../BlogSingleSidebar";
import BlogSingleSidebar1 from "../BlogSingleSidebar1";
import { AuthProvider } from "../../AuthContext";

const AllRoute = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: For smooth scrolling
    });
  }, []);

  return (
    <AuthProvider>
      <div className="App">
        <Router>
          <Routes>
            <Route path="/" element={<Homepage />} />
            <Route path="/about" element={<Aboutpage />} />
            <Route path="/services" element={<ServicePage />} />
            <Route path="/blog" element={<BlogPage />} />
            <Route path="/Routine-Maintenance" element={<ServiceDetails5 />} />
            <Route path="/Complex-Repairs" element={<ServiceDetails2 />} />
            <Route path="/Car-Inspection" element={<ServiceDetails3 />} />
            <Route
              path="/Car-Denting-and-Painting"
              element={<ServiceDetails4 />}
            />
            <Route path="/Car-Custom-Repairs" element={<ServiceDetails1 />} />
            <Route path="/Car-AC-Services" element={<ServiceDetails />} />
            <Route path="/Cookies-Policy" element={<TestimonialPage />} />
            <Route path="/Privacy-Policy" element={<TestimonialPage1 />} />
            <Route path="/Terms-&-Conditions" element={<TestimonialPage2 />} />
            <Route path="/contact" element={<ContactPages />} />
            <Route
              path="/Signs-Your-Car-Needs-Routine-Maintenance"
              element={<BlogSingleSidebar />}
            />
            <Route
              path="/When-Should-You-Consider-Getting-a-Car-Inspection"
              element={<BlogSingleSidebar1 />}
            />
            <Route path="*" element={<ErrorPage />} />
            <Route path="/404" element={<ErrorPage />} />
          </Routes>
        </Router>
      </div>
    </AuthProvider>
  );
};

export default AllRoute;
