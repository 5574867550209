import React from "react";

// components
import Navbar from "../../components/Navbar";
import PageTitle from "../../components/breadcumb";
import ServicesSingle3 from "../../components/servicesSingle3";
import FooterSection from "../../components/Footer";

const ServiceDetails = () => {
  return (
    <div className="page-wrapper inner-page home-s-3">
      <Navbar />
      <PageTitle
        bdtitle={"Car Inspection"}
        bdsub={"Car Inspection"}
      />
      <ServicesSingle3 />
      <FooterSection />
    </div>
  );
};

export default ServiceDetails;
