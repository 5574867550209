import React from "react";

// components
import Navbar from "../../components/Navbar";
import PageTitle from "../../components/breadcumb";
import FooterSection from "../../components/Footer";
import Testimonial2 from "../../components/testimonial2";

const TestimonialPage = () => {
  return (
    <div className="page-wrapper inner-page home-s-3">
      <Navbar />
      <PageTitle
        bdtitle={"Terms & Conditions of Momelizza"}
        bdsub={"Terms & Conditions"}
      />
      <Testimonial2 />
      <FooterSection />
    </div>
  );
};

export default TestimonialPage;
